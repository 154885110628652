import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles(theme => ({
  popoverButton: {
    margin: theme.spacing(1),
  },
}));

const SimplePopover = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { name, children } = props;

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        className={classes.popoverButton}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        {name}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Card>
          {children}
        </Card>
      </Popover>
    </div>
  );
}

export default SimplePopover;