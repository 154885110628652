import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { PresetTripCard } from './components';

const presetTrips = [
{
    trip_hash: 0,
    name: 'Everything',
    description: 'Rate every city and we\'ll find you the best trip' ,
    cities: {
      "MAD": {"cityStayRange": [2, 3], "rating": 4},
      "FLR": {"cityStayRange": [2, 4], "rating": 3},
      "DUB": {"cityStayRange": [1, 2], "rating": 2},
      "AMS": {"cityStayRange": [2, 4], "rating": 4},
      "BER": {"cityStayRange": [2, 4], "rating": 4},
      "LIS": {"cityStayRange": [2, 3], "rating": 3},
      "ROM": {"cityStayRange": [3, 5], "rating": 5},
      "BEG": {"cityStayRange": [2, 3], "rating": 3},
      "ATH": {"cityStayRange": [2, 4], "rating": 3},
      "VIE": {"cityStayRange": [1, 2], "rating": 2},
      "SHM": {"cityStayRange": [2, 4], "rating": 4},
      "SPB": {"cityStayRange": [3, 4], "rating": 5},
      "MUN": {"cityStayRange": [3, 4], "rating": 4},
      "RIX": {"cityStayRange": [3, 4], "rating": 4},
      "LON": {"cityStayRange": [1, 2], "rating": 2},
      "TUN": {"cityStayRange": [3, 4], "rating": 4},
      "AFC": {"cityStayRange": [2, 4], "rating": 3},
      "MCW": {"cityStayRange": [3, 5], "rating": 5},
      "KYV": {"cityStayRange": [3, 4], "rating": 5},
      "GNV": {"cityStayRange": [1, 2], "rating": 2},
    }
  },
  {
    trip_hash: 1,
    name: 'Romance Languages',
    description: 'brush up on your Spanish, French and Italian',
    cities: {
      "MAD": {"cityStayRange": [2, 4], "rating": 5},
      "FLR": {"cityStayRange": [2, 3], "rating": 3},
      "ROM": {"cityStayRange": [3, 5], "rating": 5},
      "LIS": {"cityStayRange": [2, 3], "rating": 4},
    }
  },
  {
    trip_hash: 2,
    name: 'Germanic Languages',
    description: 'Learn some German, and maybe English',
    cities: {
      "DUB": {"cityStayRange": [1, 2], "rating": 3},
      "AMS": {"cityStayRange": [2, 4], "rating": 5},
      "BER": {"cityStayRange": [2, 4], "rating": 4},
      "VIE": {"cityStayRange": [1, 3], "rating": 3},
      "MUN": {"cityStayRange": [3, 5], "rating": 3}
    }
  },
  {
    trip_hash: 3,
    name: 'Road Less Traveled',
    description: 'Your friends probably haven\'t been here yet',
    cities: {
      "LIS": {"cityStayRange": [2, 3], "rating": 4},
      "MUN": {"cityStayRange": [3, 4], "rating": 5},
      "BEG": {"cityStayRange": [2, 4], "rating": 4},
      "BER": {"cityStayRange": [2, 5], "rating": 4},
      "AMS": {"cityStayRange": [2, 3], "rating": 5}
    }
  },
  {
    trip_hash: 4,
    name: 'Comfortable Destinations',
    description: 'Go here with your parents',
    cities: {
      "MAD": {"cityStayRange": [2, 3], "rating": 5},
      "FLR": {"cityStayRange": [2, 3], "rating": 5},
      "DUB": {"cityStayRange": [1, 2], "rating": 3},
      "ROM": {"cityStayRange": [3, 4], "rating": 4},
      "VIE": {"cityStayRange": [2, 3], "rating": 4}
    }
  },
  {
    trip_hash: 5,
    name: 'Iron Curtain',
    description: 'Go here to get radiation poisoning',
    cities: {
      "KYV": {"cityStayRange": [2, 3], "rating": 5},
      "MCW": {"cityStayRange": [2, 3], "rating": 5},
      "SPB": {"cityStayRange": [1, 2], "rating": 4},
      "BEG": {"cityStayRange": [3, 4], "rating": 4},
      "BER": {"cityStayRange": [2, 3], "rating": 4},
      "DUB": {"cityStayRange": [1, 2], "rating": 2},
      "LON": {"cityStayRange": [1, 2], "rating": 2}
    }
  },
]

const PresetTripCardStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  imageContainer: {
    height: 64,
    width: 64,
    margin: '0 auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '100%'
  },
  details: {
    width: '100%'
  },
  statsItem: {
    display: 'flex',
    alignItems: 'center'
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1)
  }
}));


const PresetTrips = props => {
  return (
    <div>
      <div>
        <Grid
          container
          spacing={2}
        >
          {presetTrips.map(trip => (
            <Grid
              item
              key={trip.trip_hash}
              lg={12}
              md={12}
              xs={12}
            >
            <PresetTripCard
              key={trip.trip_hash}
              trip={trip}
              styles={PresetTripCardStyles}
              loadPresetItineraries={props.loadPresetItineraries}
              destinationRows={props.destinationRows}
            />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default PresetTrips;
