import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { 
  // TitleBar, 
  OptionsBar
} from './components';
import { AppBar, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
    // commented this out to get rid of a bunch of errors of the form: "Warning: React does not recognize the `requestStatus` prop on a DOM element."
      // {...rest}
      className={clsx(classes.root, className)}
    >
      {/*<TitleBar />*/}
      <Divider />
      <OptionsBar
        onSidebarOpen={onSidebarOpen}
        maxPrice={props.maxPrice}
        homeCity={props.homeCity}
        tripDays={props.tripDays}
        numCities={props.numCities}
        destinationRows={props.destinationRows}
        earliestDepartureDate={props.earliestDepartureDate}
        latestReturnDate={props.latestReturnDate}
        onMaxPriceSliderChange={props.onMaxPriceSliderChange}
        onMaxPriceTextChange={props.onMaxPriceTextChange}
        onHomeCityChange={props.onHomeCityChange}
        onCityRemove={props.onCityRemove}
        onDepartureDateChange={props.onDepartureDateChange}
        onReturnDateChange={props.onReturnDateChange}
        onTripDaysSliderChange={props.onTripDaysSliderChange}
        onTripDaysSliderCommitChange={props.onTripDaysSliderCommitChange}
        onNumCitiesSliderCommitChange={props.onNumCitiesSliderCommitChange}
        onNumCitiesSliderChange={props.onNumCitiesSliderChange}
        onTripDaysTextChange={props.onTripDaysTextChange}
        rankedCities={props.rankedCities}
        onImportanceChange={props.onImportanceChange}
        onCityDaysSliderChange={props.onCityDaysSliderChange}
        onCityDaysSliderChangeCommitted={props.onCityDaysSliderChangeCommitted}
        infoMessage={props.infoMessage}
        handleGenerateItinerariesButtonClick={props.handleGenerateItinerariesButtonClick}
        requestStatus={props.requestStatus}
      />
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
