import React, { useState } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
// import MoreVert from '@material-ui/icons/MoreVert';
import {
  Card,
  CardContent,
  CardActions,
  IconButton,
  Typography,
  Grid,
  Divider,
  Link,
  // Menu,
  // MenuItem,
  TableRow,
  TableCell,
  Table
} from '@material-ui/core';

const constructCitiesList = (flights, destinationRows) => {
  let previous_flight = null;
  let cities = '';
  flights.forEach(flight => {
    if (previous_flight) {
      cities +=
        destinationRows[flight.source].threeLetterCode +
        ' (' +
        (flight.date - previous_flight.date) +
        ') -> ';
    }
    previous_flight = flight;
  });
  return cities.substring(0, cities.length - 4); // delete the final four characters: ' -> '
};

const daysSinceEpochToDateString = daysSinceEpoch => {
  // Convert days to milliseconds (1 day = 86,400,000 milliseconds)
  let flightDate = new Date(daysSinceEpoch * 86400000);

  // Format the date string
  return (
    flightDate.toDateString().substring(0, 3) + // Day of the week
    ' ' +
    (flightDate.getMonth() + 1).toString().padStart(2, '0') + // Month (padded to 2 digits)
    '/' +
    flightDate
      .getDate()
      .toString()
      .padStart(2, '0') // Date (padded to 2 digits)
  );
};

const determineTotalFlightsCost = flights => {
  let price = 0;
  flights.forEach(flight => {
    price += flight.fare;
  });
  return price;
};

const numDays = trip => {
  return trip.flights[trip.flights.length - 1].date - trip.flights[0].date;
};

const TripCard = props => {
  const { trip, styles, destinationRows, ...rest } = props;

  const [openCard, setOpenCard] = useState(false);
  // const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCardOpenToggle = () => {
    setOpenCard(!openCard);
  };

  // function handleMenuOpen(event) {
  //   setAnchorEl(event.currentTarget);
  // }

  // function handleMenuSelect(event) {
  //   alert('this feature is coming soon');
  // }

  // function handleMenuClose() {
  //   setAnchorEl(null);
  // }

  return (
    <Card onClick={() => props.onTripSelection(trip)} {...rest}>
      {/* <>this is a trip card!</> */}
      <CardActions>
        <Grid container justify="space-between">
          <Grid className={styles.statsItem} item>
            <Typography display="inline" variant="body2">
              {daysSinceEpochToDateString(trip.flights[0].date) +
                ' - ' +
                daysSinceEpochToDateString(
                  trip.flights[trip.flights.length - 1].date
                )}
            </Typography>
          </Grid>
          <Grid className={styles.statsItem} item>
            <Typography display="inline" variant="body2">
              {numDays(trip)} Days
              {/* // TODO: find out why this was here in the first place?
              // {props.isSelected ? 'selected ' :''} */}
            </Typography>
          </Grid>
        </Grid>
      </CardActions>
      <Divider />
      <CardContent>
        <Typography align="right" gutterBottom variant="h4">
          ${determineTotalFlightsCost(trip.flights)}
        </Typography>
        <Typography align="left" gutterBottom variant="h4">
          {constructCitiesList(trip.flights, destinationRows)}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid container justify="space-between">
          <Grid className={styles.statsItem} item>
            <IconButton>
              {openCard && <KeyboardArrowDown onClick={handleCardOpenToggle} />}
              {!openCard && <ChevronRightIcon onClick={handleCardOpenToggle} />}
            </IconButton>
          </Grid>
          <Grid className={styles.statsItem} item>
            {/*<IconButton onClick={handleMenuOpen}>
            <MoreVert />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose}>Save this trip</MenuItem>
            <MenuItem onClick={handleMenuClose}>More Like this</MenuItem>
          </Menu>*/}
          </Grid>
        </Grid>
      </CardActions>
      <Table>
        {openCard &&
          trip.flights.map(flight => (
            <TableRow className={styles.details} key={flight.purchaseLink}>
              <TableCell component="th" scope="row">
                <Link target="_blank" href={flight.purchaseLink}>
                  {destinationRows[flight.source].name} to{' '}
                  {destinationRows[flight.dest].name}
                </Link>
              </TableCell>
              <TableCell component="th" scope="row">
                {daysSinceEpochToDateString(flight.date)}
              </TableCell>
              <TableCell component="th" scope="row">
                ${flight.fare}
              </TableCell>
            </TableRow>
          ))}
      </Table>
    </Card>
  );
};

export default TripCard;
