import { gql } from '@apollo/client';

export const UPDATE_TRIP_PREFERENCES_FIELD = gql`
  mutation updateTripPreferencesField($field: String!, $value: String!) {
    updateTripPreferencesField(field: $field, value: $value) {
      result
    }
  }
`;

export const UPDATE_USER_CITY = gql`
  mutation updateUserCity(
    $threeLetterCode: String!
    $field: String!
    $value: String!
  ) {
    updateUserCity(
      threeLetterCode: $threeLetterCode
      field: $field
      value: $value
    ) {
      result
    }
  }
`;

export const REMOVE_USER_CITY = gql`
  mutation removeUserCity($threeLetterCode: String!) {
    removeUserCity(threeLetterCode: $threeLetterCode) {
      result
    }
  }
`;
