import React,  { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Slider from '@material-ui/core/Slider'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import Typography from '@material-ui/core/Typography'

class AboutPage extends Component {
  state = {}

  render() {
    const {
      styles,
    } = this.props

    return (
      <Typography className={styles.aboutBox}>
        Want to go to many places for small money? This app is for you! Just tell us some broad info on what you want out of your next trip, and we'll put together some iteneraries for you. To get started, you can go straight to the "Trip Preferences" and "Destinations" tabs and tell us which cities you want to go to, how badly you want to go to each (you'll see five dots next to each city for this) and when you want to go. Or, if you want a quick start, we've got some pre-canned searches ready to go for you on the right side of the map. You can use these to get started and then modify the search parameters to your liking.
      </Typography>
    )
  }
}

export default AboutPage