import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Drawer } from '@material-ui/core';

import { Itineraries, PresetTrips } from './components';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  drawerDesktop: {
    width: '50%',
    backgroundColor: theme.palette.steel_grey,
    [theme.breakpoints.up('sm')]: {
      marginTop: 80,
      height: 'calc(100% - 80px)'
    }
  },
  drawerMobile: {
    width: '90%',
    backgroundColor: theme.palette.steel_grey,
    [theme.breakpoints.up('sm')]: {
      marginTop: 80,
      height: 'calc(100% - 80px)'
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const {
    open,
    variant,
    onClose,
    className,
    loadPresetItineraries,
    setCityList,
    trips,
    destinationRows,
    ...rest
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true
  });

  return (
    <Drawer
      anchor="right"
      classes={{
        paper: isDesktop ? classes.drawerDesktop : classes.drawerMobile
      }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        {props.trips.length > 0 && (
        <Itineraries
          trips={trips}
          destinationRows={destinationRows}
          setCityList={setCityList}
        />
        )
        }
        {props.trips.length === 0 && (
            <></>
          // <div>
          //   <PresetTrips
          //     destinationRows={destinationRows}
          //     loadPresetItineraries={loadPresetItineraries}
          //   />
          //   <>no trips!</>
          // </div>
        )}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
