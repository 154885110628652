import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Slider from '@material-ui/core/Slider';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';

const homeCities = [
  { key: 'NYC', name: 'New York' },
  { key: 'BOS', name: 'Boston' },
  { key: 'LAX', name: 'Los Angeles' }
];

class TripPreferences extends Component {
  state = {};

  render() {
    const {
      styles,
      maxPrice,
      homeCity,
      tripDays,
      numCities,
      earliestDepartureDate,
      latestReturnDate,
      onMaxPriceSliderChange,
      onHomeCityChange,
      onDepartureDateChange,
      onReturnDateChange,
      onTripDaysSliderChange,
      onTripDaysSliderCommitChange,
      onNumCitiesSliderCommitChange,
      onNumCitiesSliderChange
    } = this.props;

    return (
      <Grid container className={styles.tripPreferencesContainer}>
        <Grid item>
          {/* <Typography id="range-slider" gutterBottom>
            Max Price: ${maxPrice}
          </Typography>
          <Slider
            className={styles.priceSlider}
            value={maxPrice}
            max={3000}
            valueLabelDisplay="auto"
            onChange={onMaxPriceSliderChange}
            aria-labelledby="range-slider"
          /> */}
          <Typography id="range-slider" gutterBottom>
            Total number of days on trip: {tripDays[0]} - {tripDays[1]}
          </Typography>
          <Slider
            value={tripDays}
            max={40}
            onChange={onTripDaysSliderChange}
            onChangeCommitted={onTripDaysSliderCommitChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
          />
          <Typography id="range-slider" gutterBottom>
            Total number of cities to visit: {numCities[0]} - {numCities[1]}
          </Typography>
          <Slider
            value={numCities}
            min={1}
            max={10}
            onChange={onNumCitiesSliderChange}
            onChangeCommitted={onNumCitiesSliderCommitChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
          />
          <Typography id="range-slider" gutterBottom>
            Home City
          </Typography>
          <Select
            value={homeCity}
            onChange={onHomeCityChange}
            inputProps={{
              name: 'homeCity'
            }}
            className={styles.homeCityPicker}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {homeCities.map(city => (
              <MenuItem key={city.key} value={city.key}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container className={styles.grid} justify="space-around">
              <KeyboardDatePicker
                margin="normal"
                disablePast={true}
                id="earliest-departure-date"
                label="Earliest Departure Date"
                minDate={
                  new Date(new Date().setMonth(new Date().getMonth() + 2))
                }
                value={earliestDepartureDate}
                onChange={onDepartureDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
              <KeyboardDatePicker
                margin="normal"
                disablePast={true}
                id="latest-return-date"
                label="Latest Return Date"
                value={latestReturnDate}
                maxDate={
                  new Date(new Date().setMonth(new Date().getMonth() + 6))
                }
                onChange={onReturnDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    );
  }
}

export default TripPreferences;
