import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Toolbar, Link, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  toolbar: {
    justifyContent: 'space-between',
    backgroundColor: theme.palette.steel_sky,
  },
  left: {
    flex: 1,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.black,
    marginLeft: theme.spacing(3),
  },
  tagline: {
    fontSize: 32,
    color: theme.palette.common.black,
    display: 'inline-block',
    marginLeft: 10,
    padding: 20,
    [theme.breakpoints.up('md')]: {
      paddingTop: '0.8em'
    }
  }

}));

const TitleBar = props => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.left}>
        <Link
          color="inherit"
          variant="h6"
          underline="none"
          className={classes.rightLink}
          href="/"
        >
          {'Fast Vacay'}
        </Link>
      </div>
      <RouterLink to="/">
        <img
          alt="Logo"
          width={30}
          src="/images/logos/logo.svg"
        />
        <Typography color="inherit"
          variant="h6"
          underline="none"
          className={classes.tagline}
        >
          {'Airo'}
        </Typography>
      </RouterLink>
      <div className={classes.right}>
        <Link
          color="inherit"
          variant="h6"
          underline="none"
          className={classes.rightLink}
          href="/"
        >
          {'Sign In'}
        </Link>
        <Link
          variant="h6"
          underline="none"
          className={classes.rightLink}
          href="/"
        >
          {'About'}
        </Link>
      </div>
    </Toolbar>
  );
};

TitleBar.propTypes = {
  className: PropTypes.string
};

export default TitleBar;
