import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Login extends Component {
  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const token = searchParams.get('token'); // Assuming the URL parameter is called 'token'

    if (token) {
      localStorage.setItem('token', token);
    } else {
      alert('error logging in!!');
    }
    this.props.history.push('/');
  }

  render() {
    // This component does not render anything
    return null;
  }
}

export default withRouter(Login);
