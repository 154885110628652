import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import aws from 'aws-sdk';
import { nanoid } from 'nanoid';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ContactUs = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const submitContactUsDataToS3 = values => {
    console.log(values)    

    const s3 = new aws.S3();
    const S3_BUCKET = 'airoapp';

    const fileName = `contact-us-submissions/${nanoid()}`; // create a unique file name
    // const fileType = contentType; // the content-type of the file
    const fileType = "application/json"; // Assuming you're uploading JSON data

    console.log(fileType, fileType)
    // const s3Params = {
    //     Bucket: S3_BUCKET,
    //     Fields: {
    //         key: fileName
    //     },
    //     Conditions: [
    //         ['content-length-range', 0, 100000000],
    //         ['starts-with', '$Content-Type', 'image/'],
    //         ['eq', '$x-amz-meta-user-id', userId],
    //     ],
    //     ContentType: fileType
    // };

    // const data = s3.createPresignedPost(s3Params);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="Questions? Comments? Send us an email!"
          title="Contact Us!"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="First name"
                margin="dense"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Last name"
                margin="dense"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                name="phone"
                onChange={handleChange}
                type="number"
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                id="outlined-dense-multiline"
                label="Message"
                // className={clsx(classes.textField, classes.dense)}
                margin="dense"
                variant="outlined"
                multiline
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => submitContactUsDataToS3(values)}
          >
            Send!
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

ContactUs.propTypes = {
  className: PropTypes.string
};

export default ContactUs;
