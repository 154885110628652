import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import { Sidebar, Topbar, Footer } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingTop: 65,
    paddingRight: '50%'
  },
  content: {
    paddingTop: 65,
    height: '100%'
  }
}));

const Ikuzo = props => {

  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <Topbar
        onSidebarOpen={handleSidebarOpen}
        maxPrice={props.maxPrice}
        homeCity={props.homeCity}
        tripDays={props.tripDays}
        numCities={props.numCities}
        destinationRows={props.destinationRows}
        earliestDepartureDate={props.earliestDepartureDate}
        latestReturnDate={props.latestReturnDate}
        onMaxPriceSliderChange={props.onMaxPriceSliderChange}
        onMaxPriceTextChange={props.onMaxPriceTextChange}
        onHomeCityChange={props.onHomeCityChange}
        onCityRemove={props.onCityRemove}
        onDepartureDateChange={props.onDepartureDateChange}
        onReturnDateChange={props.onReturnDateChange}
        onTripDaysSliderChange={props.onTripDaysSliderChange}
        onTripDaysSliderCommitChange={props.onTripDaysSliderCommitChange}
        onNumCitiesSliderCommitChange={props.onNumCitiesSliderCommitChange}
        onNumCitiesSliderChange={props.onNumCitiesSliderChange}
        onTripDaysTextChange={props.onTripDaysTextChange}
        rankedCities={props.rankedCities}
        onImportanceChange={props.onImportanceChange}
        onCityDaysSliderChange={props.onCityDaysSliderChange}
        onCityDaysSliderChangeCommitted={props.onCityDaysSliderChangeCommitted}
        infoMessage={props.infoMessage}
        handleGenerateItinerariesButtonClick={props.handleGenerateItinerariesButtonClick}
        requestStatus={props.requestStatus}
      />
      <main className={classes.content}>
        {children}
      </main>
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        setCityList={props.setCityList}
        destinationRows={props.destinationRows}
        variant={isDesktop ? 'persistent' : 'temporary'}
        loadPresetItineraries={props.loadPresetItineraries}
        trips={props.trips}
      />
      <Footer />
    </div>
  );
};

Ikuzo.propTypes = {
  children: PropTypes.node
};

export default Ikuzo;
