import React, { Component } from 'react';

import ClearIcon from '@material-ui/icons/Clear';

import { Slider, IconButton, Typography } from '@material-ui/core';
import Rating from 'react-rating';

class DestinationCityList extends Component {
  render() {
    const {
      styles,
      rankedCities,
      onImportanceChange,
      onCityDaysSliderChange,
      onCityDaysSliderChangeCommitted,
      onCityRemove,
      destinationRows
    } = this.props;

    return (
      <div className={styles.destinationCityContainer}>
        {Object.values(destinationRows).map(row => (
          <div>
            <Typography className={styles.cityName}>{row.name}</Typography>
            <div>
              {rankedCities[row.threeLetterCode] && (
                <IconButton
                  onClick={e => onCityRemove(e, row.threeLetterCode)}
                  className={styles.clearButton}>
                  <ClearIcon />
                </IconButton>
              )}
              <Rating
                className={styles.importanceRatingCell}
                initialRating={
                  rankedCities[row.threeLetterCode]
                    ? rankedCities[row.threeLetterCode].rating
                    : 0
                }
                onChange={e => onImportanceChange(e, row.threeLetterCode)}
              />
            </div>
            {rankedCities[row.threeLetterCode] && (
              <div>
                <Typography>
                  Number of nights in {row.name}:{' '}
                  {rankedCities[row.threeLetterCode].cityStayRange[0]} -{' '}
                  {rankedCities[row.threeLetterCode].cityStayRange[1]}
                </Typography>
                <Slider
                  value={rankedCities[row.threeLetterCode].cityStayRange}
                  max={15}
                  min={1}
                  onChangeCommitted={(e, newValues) => {
                    onCityDaysSliderChangeCommitted(e, newValues, row.threeLetterCode);
                  }}
                  onChange={(e, newValues) => {
                    onCityDaysSliderChange(e, newValues, row.threeLetterCode);
                  }}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default DestinationCityList;
