import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import { Ikuzo as IkuzoLayout } from '../../layouts';
import { Home as HomeView } from '../../views';
import { useQuery } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';

import { CITIES, TRIPS, TRIP_PREFERENCES } from '../../common/queries';
import {
  UPDATE_TRIP_PREFERENCES_FIELD,
  UPDATE_USER_CITY,
  REMOVE_USER_CITY
} from '../../common/mutations';

const millisecondsPerDay = 86400000;

function convertArrayToObject(array) {
  const result = {};
  array.forEach(item => {
    const { threeLetterCode, rating, stayLowerBound, stayUpperBound } = item;
    result[threeLetterCode] = {
      cityStayRange: [stayLowerBound, stayUpperBound],
      rating
    };
  });
  return result;
}

const infoMessages = {
  earlyReturnDate:
    'Your return date is earlier than your departure date. Please update your dates and try again.',
  missingCities: 'Please select at least one city.',
  missingHomeCity: 'Please select your home city.',
  minDaysTooBig: 'Your min days is higher than your max days for'
};

const defaultCitiesForTesting = {
  VIE: {
    cityStayRange: [2, 5],
    rating: 5
  },
  BEG: {
    cityStayRange: [2, 5],
    rating: 5
  },
  ROM: {
    cityStayRange: [2, 5],
    rating: 5
  },
  AMS: {
    cityStayRange: [2, 5],
    rating: 5
  },
  MAD: {
    cityStayRange: [2, 5],
    rating: 5
  }
};

const daysSinceEpochToDate = daysSinceEpoch => {
  const millisecondsPerDay = 86400000;
  return new Date(daysSinceEpoch * millisecondsPerDay);
};

class IkuzoRouteWithLayout extends Component {
  // this state needs to come from the backend
  state = {
    // homeCity: 'NYC',
    selectedCities: [],
    earliestDepartureDate: new Date(
      new Date().setMonth(new Date().getMonth() + 1)
    ), // one month from today
    latestReturnDate: new Date(new Date().setMonth(new Date().getMonth() + 3)), // two months from today
    rankedCities: {},
    requestStatus: 'Generate Itineraries',
    maxPrice: 2000,
    trips: [],
    tripDays: [7, 10],
    numCities: [1, 4],
    destinationRows: {}
  };

  removeUserCityOnBackend = async threeLetterCode => {
    console.log('would remove: ', threeLetterCode);
    try {
      const { data } = await this.props.client.mutate({
        mutation: REMOVE_USER_CITY,
        variables: {
          threeLetterCode: threeLetterCode
        }
      });
    } catch (error) {
      console.error('Error performing mutation:', error);
    }
  };

  handleCityRemove = (event, city) => {
    this.removeUserCityOnBackend(city);

    let cities = this.state.rankedCities;
    delete cities[city];
    this.setState({ rankedCities: cities });
  };

  setCityList = cities => {
    this.setState({ selectedCities: cities });
  };

  handleDrawerOpen = () => {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  };

  handleDrawerClose = () => {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    this.updateTripPreferencesField(event.target.name, event.target.value);
  };

  updateTripPreferencesField = async (field, value) => {
    try {
      const { data } = await this.props.client.mutate({
        mutation: UPDATE_TRIP_PREFERENCES_FIELD,
        variables: {
          field: field,
          value: value
        }
      });
    } catch (error) {
      console.error('Error performing mutation:', error);
    }
  };

  updateUserCityInBackend = async (threeLetterCode, field, value) => {
    try {
      const { data } = await this.props.client.mutate({
        mutation: UPDATE_USER_CITY,
        variables: {
          threeLetterCode: threeLetterCode,
          field: field, // three possible values: 'rating', 'cityStayRange'
          value: value
        }
      });
    } catch (error) {
      console.error('Error performing mutation:', error);
    }
  };

  handleTripDaysSliderChange = (event, updatedTripDays) => {
    this.setState({ tripDays: updatedTripDays });
  };

  handleTripDaysSliderCommitChange = (event, updatedTripDays) => {
    this.setState({ tripDays: updatedTripDays });
    this.updateTripPreferencesField('daysOnTripRange', updatedTripDays);
  };

  handleNumCitiesSliderChange = (event, updatedNumCities) => {
    this.setState({ numCities: updatedNumCities });
  };

  handleNumCitiesSliderCommitChange = (event, updatedNumCities) => {
    this.setState({ numCities: updatedNumCities });
    this.updateTripPreferencesField('numCitiesRange', updatedNumCities);
  };

  handleCityDaysSliderChange = (event, cityStayRange, cityCode) => {
    this.updateCityParameter(cityCode, 'cityStayRange', cityStayRange);
  };

  handleCityDaysSliderChangeCommitted = (event, cityStayRange, cityCode) => {
    // only update the backend if the user drops the slider handle, thus committing the change
    this.updateCityParameter(cityCode, 'cityStayRange', cityStayRange);
    this.updateUserCityInBackend(cityCode, 'cityStayRange', cityStayRange);
  };

  handleTripDaysTextChange = (event, isMinimum) => {
    let newNumDays = event.target.value;
    let [currentMinimum, currentMaximum] = this.state.tripDays;

    if (isMinimum) {
      if (newNumDays > currentMaximum || newNumDays < 0) {
        return;
      }

      this.setState({ tripDays: [newNumDays, currentMaximum] });
    } else {
      if (newNumDays < currentMinimum || newNumDays > 30) {
        return;
      }

      this.setState({ tripDays: [currentMinimum, newNumDays] });
    }
  };

  handleMaxPriceSliderChange = (event, price) => {
    this.setState({ maxPrice: price });
  };

  handleMaxPriceTextChange = event => {
    if (event.target.value < 3001) {
      this.setState({ maxPrice: event.target.value });
    }
  };

  handleDepartureDateChange = date => {
    this.setState({ earliestDepartureDate: date });
    this.updateTripPreferencesField(
      'earliestDepartureDate',
      Math.floor(date.valueOf() / millisecondsPerDay)
    );
  };

  handleReturnDateChange = date => {
    this.setState({ latestReturnDate: date });
    this.updateTripPreferencesField(
      'latestReturnDate',
      Math.floor(date.valueOf() / millisecondsPerDay)
    );
  };

  updateCityParameter = (city, parameterName, parameterValue) => {
    let rankedCities = this.state.rankedCities;
    let rankedCity = rankedCities[city] ? rankedCities[city] : {};
    rankedCity[parameterName] = parameterValue;
    rankedCities[city] = rankedCity;
    this.setState({ rankedCities: rankedCities });
  };

  handleRatingChange = (rating, city) => {
    console.log('rating: ', rating, city);
    if (!this.state.rankedCities[city]) {
      this.updateCityParameter(city, 'cityStayRange', [2, 5]);
    }

    this.updateUserCityInBackend(city, 'rating', rating);

    this.updateCityParameter(city, 'rating', rating);
  };

  formatCitiesData = () => {
    let cities = [];
    for (var city in this.state.rankedCities) {
      let currentCity = {};
      currentCity['stay_range'] = this.state.rankedCities[city].cityStayRange;
      currentCity['three_letter_code'] = city;
      currentCity['score'] = this.state.rankedCities[city].rating;

      cities.push(currentCity);
    }

    return cities;
  };

  // construct the body of the request
  getRequestBody = () => {
    // TODO: remove the following two debug lines
    console.log('fruitsalad');
    console.log(this.state.homeCity.valueOf());
    return {
      home_city: this.state.homeCity.valueOf(),
      dates_range: [
        Math.floor(
          this.state.earliestDepartureDate.valueOf() / millisecondsPerDay
        ),
        Math.floor(this.state.latestReturnDate.valueOf() / millisecondsPerDay)
      ],
      num_cities_range: [2, 3],
      price_range: [0, this.state.maxPrice],
      trip_length_range: this.state.tripDays,
      cities: this.formatCitiesData()
    };
  };

  validateData = () => {
    if (this.state.earliestDepartureDate > this.state.latestReturnDate) {
      this.setState({ infoMessage: infoMessages.earlyReturnDate });
      return false;
    }

    // if (
    //   Object.entries(this.state.rankedCities).length === 0 &&
    //   this.state.rankedCities.constructor === Object
    // ) {
    if (true) {
      console.log('why isnt this setting', defaultCitiesForTesting);
      this.setState({ rankedCities: defaultCitiesForTesting });
      // this.setState({infoMessage: infoMessages.missingCities})
      return false;
    }

    for (var city in this.state.rankedCities) {
      let [minDays, maxDays] = this.state.rankedCities[city].cityStayRange;
      if (minDays && maxDays && minDays > maxDays) {
        this.setState({ infoMessage: infoMessages.minDaysTooBig + ' ' + city });
        return false;
      }
    }

    if (!this.state.homeCity) {
      this.setState({ infoMessage: infoMessages.missingHomeCity });
      return false;
    }

    return true;
  };

  loadPresetItineraries = cities => {
    this.setState({ rankedCities: cities }, function() {
      this.handleGenerateItinerariesButtonClick();
    });
  };

  handleGenerateItinerariesButtonClick = async () => {
    const { client } = this.props;
    try {
      const { data } = await client.query({
        query: TRIPS
      });

      if (data && data.trips) {
        this.setState({ trips: data.trips });
      } else {
        console.log('No trips data found in the response');
        this.setState({ trips: [] });
      }
    } catch (error) {
      console.error('Error generating itineraries:', error);
      this.setState({ trips: [] });
    }
  };

  componentDidMount() {
    // this.getDestinationCityList();
    this.fetchInitialState();
    this.fetchCities();
    console.log('did we fetch');
  }

  fetchInitialState = async () => {
    try {
      const { data } = await this.props.client.query({
        query: TRIP_PREFERENCES
      });

      if (data && data.tripPreferences) {
        const {
          homeCity,
          earliestDepartureDate,
          latestReturnDate,
          minNumDays,
          maxNumDays,
          minNumCities,
          maxNumCities
        } = data.tripPreferences;

        this.setState({
          homeCity,
          earliestDepartureDate: daysSinceEpochToDate(earliestDepartureDate),
          latestReturnDate: daysSinceEpochToDate(latestReturnDate),
          tripDays: [minNumDays, maxNumDays],
          numCities: [minNumCities, maxNumCities]
          // maxPrice
        });
      } else {
        console.log('No trip preferences data found in the response');
      }
    } catch (error) {
      console.error('Error fetching initial state:', error);
    }
  };

  fetchCities = async () => {
    try {
      const { data } = await this.props.client.query({
        query: CITIES
      });

      if (data && data.cities) {
        const destinationRows = data.cities.destinations.reduce((acc, city) => {
          acc[city.threeLetterCode] = { ...city };
          return acc;
        }, {});
        this.setState({ destinationRows });

        this.setState({
          rankedCities: convertArrayToObject(data.cities.userCities)
        });
      } else {
        console.log('No cities data found in the response');
      }
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  render() {
    const { ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={matchProps => (
          <IkuzoLayout
            maxPrice={this.state.maxPrice}
            homeCity={this.state.homeCity}
            tripDays={this.state.tripDays}
            numCities={this.state.numCities}
            destinationRows={this.state.destinationRows}
            earliestDepartureDate={this.state.earliestDepartureDate}
            latestReturnDate={this.state.latestReturnDate}
            onMaxPriceSliderChange={this.handleMaxPriceSliderChange}
            onMaxPriceTextChange={this.handleMaxPriceTextChange}
            onHomeCityChange={this.handleChange}
            onDepartureDateChange={this.handleDepartureDateChange}
            onReturnDateChange={this.handleReturnDateChange}
            onTripDaysSliderChange={this.handleTripDaysSliderChange}
            onTripDaysSliderCommitChange={this.handleTripDaysSliderCommitChange}
            onNumCitiesSliderCommitChange={
              this.handleNumCitiesSliderCommitChange
            }
            loadPresetItineraries={this.loadPresetItineraries}
            onTripDaysTextChange={this.handleTripDaysTextChange}
            onCityRemove={this.handleCityRemove}
            onNumCitiesSliderChange={this.handleNumCitiesSliderChange}
            // rankedCities={defaultCitiesForTesting}
            rankedCities={this.state.rankedCities}
            onImportanceChange={this.handleRatingChange}
            onCityDaysSliderChange={this.handleCityDaysSliderChange}
            onCityDaysSliderChangeCommitted={
              this.handleCityDaysSliderChangeCommitted
            }
            infoMessage={this.state.infoMessage}
            handleGenerateItinerariesButtonClick={
              this.handleGenerateItinerariesButtonClick
            }
            requestStatus={this.state.requestStatus}
            setCityList={this.setCityList}
            trips={this.state.trips}>
            <HomeView selectedCities={this.state.selectedCities} />
          </IkuzoLayout>
        )}
      />
    );
  }
}

IkuzoRouteWithLayout.propTypes = {
  path: PropTypes.string
};

export default withApollo(IkuzoRouteWithLayout);
