import { gql } from '@apollo/client';

export const CITIES = gql`
  query cities {
    cities {
      destinations {
        name
        threeLetterCode
      }
      userCities {
        threeLetterCode
        rating
        stayLowerBound
        stayUpperBound
      }
    }
  }
`;

export const TRIP_PREFERENCES = gql`
  query tripPreferences {
    tripPreferences {
      homeCity
      earliestDepartureDate
      latestReturnDate
      minNumDays
      maxNumDays
      minNumCities
      maxNumCities
    }
  }
`;

export const TRIPS = gql`
  query trips {
    trips {
      flights {
        date
        fare
        source
        dest
        purchaseLink
      }
    }
  }
`;
