import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { ProductCard } from './components';

const productCardStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    width: '100%'
  },
  details: {
    width: '100%'
  },
  statsItem: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const getCities = flights => {
  let cities = [];
  if (flights.length > 0) {
    flights.forEach(flight => {
      cities.push(flight.source);
    });
    cities.push(flights[0].source);
  }
  return cities;
};

const Itineraries = props => {
  const [selectedTripHash, setSelectedTripHash] = React.useState(null);

  function handleTripSelection(trip) {
    setSelectedTripHash(trip.trip_hash);
    props.setCityList(getCities(trip.flights));
  }

  return (
    <div>
      <div style={{ height: '35px', width: '200px' }}>
        {/* we got trips! */}
      </div>
      <div>
        <Grid container spacing={2}>
          {props.trips.map(trip => (
            <Grid item key={trip.trip_hash} lg={12} md={12} xs={12}>
              <ProductCard
                destinationRows={props.destinationRows}
                key={trip.trip_hash}
                trip={trip}
                styles={productCardStyles}
                onTripSelection={handleTripSelection}
                isSelected={trip.trip_hash === selectedTripHash}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default Itineraries;
