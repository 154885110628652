import React, { Component } from 'react'
import GoogleMap from 'google-map-react'

import Marker from './marker'

const citiesAndCoordinates = {
  BOS:[42.3601, -71.0589, 'Boston'],
  NYC:[40.7128, -74.0060, 'New York'],
  MAD:[40.4168, -3.7038, 'Madrid'],
  FLR:[43.7696, 11.2558, 'Florence'],
  DUB:[53.3498, -6.2603, 'Dublin'],
  AMS:[52.3667, 4.8945, 'Amsterdam'],
  BER:[52.5200, 13.4050, 'Berlin'],
  LIS:[38.7223, -9.1393, 'Lisbon'],
  ROM:[41.9028, 12.4964, 'Rome'],
  BEG:[44.7866, 20.4489, 'Belgrade'],
  ATH:[37.9838, 23.7275, 'Athens'],
  VIE:[48.2082, 16.3738, 'Vienna'],
  SHM:[59.3320213,17.8996483, 'Stockholm'],
  SPB:[59.9390094,29.5302902, 'Saint Petersburg, Russia'],
  MUN:[48.1548256,11.4017512, 'Munich'],
  RIX:[56.9711614,23.848983, 'Riga, Latvia'],
  LON:[51.528308,-0.3817826, 'London'],
  TUN:[36.7948624,10.0732371, 'Tunis, Tunisia'],
  AFC:[40.6666893,14.4518407, 'Amalfi Coast, Italy'],
  LAX:[34.0201613,-118.6919286, 'Los Angeles'],
  MCW:[55.580748,36.8251083, 'Moscow'],
  KYV:[50.401699,30.2525057, 'Kyiv'],
  GNV:[46.2050242,6.109069, 'Geneva'],
}

class FlightMap extends Component {
  static itineraryPolyline = null;

  constructor (props) {
    super(props)

    this.state = {
      mapsLoaded: false,
      map: null,
      maps: null,
      markers: [
        {lat: 53.42728, lng: -6.24357},
        {lat: 43.681583, lng: -79.61146}
      ],
      center: {lat: citiesAndCoordinates['BER'][0], lng:citiesAndCoordinates['BER'][1] + 30}
    }
  }

  getCoordinates (city) {
    let cityAndCoordinates = citiesAndCoordinates[city]
    if (!cityAndCoordinates) {
      cityAndCoordinates = citiesAndCoordinates['BER']
    }

    return {lat: cityAndCoordinates[0], lng: cityAndCoordinates[1]}
  }

  onMapLoaded (map, maps) {
    this.fitBounds(map, maps)

    this.setState({
      ...this.state,
      mapsLoaded: true,
      map: map,
      maps: maps
    })

    this.state.map.setCenter(this.state.center)
    this.state.map.setZoom(4)
  }

  fitBounds (map, maps) {
    var bounds = new maps.LatLngBounds()
    for (let marker of this.props.selectedCities) {
      bounds.extend(
        new maps.LatLng(marker.lat, marker.lng)
      )
    }
    map.fitBounds(bounds)
  }

  getPath() {
    let pathMarkers = []

    this.props.selectedCities.forEach((city) => {
      // if (!['NYC', 'BOS'].includes(city)) {
        pathMarkers.push(this.getCoordinates(city))
      // }
    })
    return pathMarkers
  }

  getCenter() {
    let latitudes = 0
    let longitudes = 0
    this.props.selectedCities.forEach((city) => {
      if (!['NYC', 'BOS'].includes(city)) {
        latitudes += this.getCoordinates(city).lat
        longitudes += this.getCoordinates(city).lng
      }
    })

    // we're looking at all cities in the itinerary, minus the home city
    let num_cities = this.props.selectedCities.length - 2
    return {lat: latitudes/num_cities, lng:(longitudes/num_cities) + 40}
  }

  updateItineraryVisualization() {
    this.renderPolyline()

    if (this.props.selectedCities.length > 0) {
      this.state.map.setCenter(this.getCenter())
    }
  }

  renderPolyline() {
    let newPolyline = new this.state.maps.Polyline({
      path: this.getPath(),
      geodesic: true,
      strokeColor: '#000000',
      strokeOpacity: 1.0,
      icons: [{
        icon: { path: this.state.maps.SymbolPath.FORWARD_CLOSED_ARROW },
        offset: '50%'},
        {icon: { path: this.state.maps.SymbolPath.FORWARD_CLOSED_ARROW },
        offset: '55%'},
        {icon: { path: this.state.maps.SymbolPath.FORWARD_CLOSED_ARROW },
        offset: '60%'
      }],
      strokeWeight: 2
    })

    if (this.itineraryPolyline) {
      this.itineraryPolyline.setMap(null)
    }
    newPolyline.setMap(this.state.map)

    this.itineraryPolyline = newPolyline
  }


  render () {
    return (
      // <>map goes here</>
      <GoogleMap
        bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY}}
        // bootstrapURLKeys={{key: ''}}
        style={{height: '100vh', width: '100%'}}
        defaultCenter={this.state.center}
        defaultZoom={this.props.zoom}
        onGoogleApiLoaded={({map, maps}) => this.onMapLoaded(map, maps)}
      >
        {/*this.props.selectedCities && this.props.selectedCities.map(city => (
          {<Marker text={citiesAndCoordinates[city][2]} lat={citiesAndCoordinates[city][0]} lng={citiesAndCoordinates[city][1]} />}
        ))*/}
        { this.state.mapsLoaded && this.updateItineraryVisualization() }
      </GoogleMap>
    )
  }
}

FlightMap.defaultProps = {
  markers: [
    {lat: 53.42728, lng: -6.24357},
    {lat: 43.681583, lng: -79.61146}
  ],
  center: [42.3601, -71.0589],
  zoom: 6
}

export default FlightMap