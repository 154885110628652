import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Toolbar, IconButton, Hidden, Typography, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import SimplePopover from './components/SimplePopover'
import { DestinationCityList, TripPreferences, AboutPage, ContactUs } from './components'



const useStyles = makeStyles(theme => ({
  toolbar: {
    backgroundColor: theme.palette.white,
  },
  iconImage: {
    verticalAlign: 'middle',
    width: '30px'
  },
  flexGrow: {
    flexGrow: 1
  },
  left: {
    flex: 1,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  MenuIcon: {
    color: theme.palette.common.steel_black,
  },
  tagline: {
    verticalAlign: 'middle',
    fontSize: 32,
    color: theme.palette.common.black,
    display: 'inline-block',
    marginLeft: 10,
    margin: 30,
  }

}));

const tripPreferencesStyles = makeStyles(theme => ({
  tripPreferencesContainer: {
    margin: `${theme.spacing(3)}px`
  },
  priceSlider: {
    width: '90%'
  },
  homeCityPicker: {
    width: 'auto',
    display: 'block'
  },
  grid: {
    margin: `0 ${theme.spacing(2)}px`
  },
}));

const destinationCityStyles = makeStyles(theme => ({
  destinationCityContainer: {
    margin: `${theme.spacing(3)}px`,
    padding: `${theme.spacing(3)}px`,
  },
  clearButton: {
    paddingRight: `${theme.spacing(3)}px`,
    vertical: 'middle'
  },
}));

const aboutPageStyles = makeStyles(theme => ({
  aboutBox: {
    padding: '30px 30px',
  },
}));

const OptionsBar = props => {
  const { onSidebarOpen } = props;

  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar}>
    <RouterLink to="/">
      <img className={classes.iconImage}
        alt="Logo"
        src="/images/logos/logo.svg"
      />
      <Typography color="inherit"
        underline="none"
        className={classes.tagline}
      >
        {'Airo'}
      </Typography>
    </RouterLink>
    
    <SimplePopover name={'About'} >
      <AboutPage
        styles={aboutPageStyles()}
        rankedCities={props.rankedCities}
        onCityRemove={props.onCityRemove}
        onImportanceChange={props.onImportanceChange}
        onCityDaysSliderChange={props.onCityDaysSliderChange}
        destinationRows={props.destinationRows}
      />
    </SimplePopover>
    <SimplePopover name={'Contact Us'} >
      <ContactUs
        styles={aboutPageStyles()}
        rankedCities={props.rankedCities}
        onCityRemove={props.onCityRemove}
        onImportanceChange={props.onImportanceChange}
        onCityDaysSliderChange={props.onCityDaysSliderChange}
        destinationRows={props.destinationRows}
      />
    </SimplePopover>
    <SimplePopover name={'Destinations'} >
      <DestinationCityList
        styles={destinationCityStyles()}
        rankedCities={props.rankedCities}
        onCityRemove={props.onCityRemove}
        onImportanceChange={props.onImportanceChange}
        onCityDaysSliderChange={props.onCityDaysSliderChange}
        onCityDaysSliderChangeCommitted={props.onCityDaysSliderChangeCommitted}
        destinationRows={props.destinationRows}
      />
    </SimplePopover>
    <SimplePopover name={'Trip Preferences'} >
      <TripPreferences
        styles={tripPreferencesStyles()}
        maxPrice={props.maxPrice}
        homeCity={props.homeCity}
        tripDays={props.tripDays}
        numCities={props.numCities}
        earliestDepartureDate={props.earliestDepartureDate}
        latestReturnDate={props.latestReturnDate}
        onMaxPriceSliderChange={props.onMaxPriceSliderChange}
        onMaxPriceTextChange={props.onMaxPriceTextChange}
        onHomeCityChange={props.onHomeCityChange}
        onDepartureDateChange={props.onDepartureDateChange}
        onReturnDateChange={props.onReturnDateChange}
        onTripDaysSliderChange={props.onTripDaysSliderChange}
        onTripDaysSliderCommitChange={props.onTripDaysSliderCommitChange}
        onNumCitiesSliderCommitChange={props.onNumCitiesSliderCommitChange}
        onNumCitiesSliderChange={props.onNumCitiesSliderChange}
        onTripDaysTextChange={props.onTripDaysTextChange}
      />
    </SimplePopover>
    <Button onClick={props.handleGenerateItinerariesButtonClick}>
      {props.requestStatus}
    </Button>

    <Typography>
      {props.infoMessage}
    </Typography>
    <div className={classes.flexGrow} />
    <div className={classes.right}>
      <Hidden smUp>
        <IconButton
          className={classes.MenuIcon}
          onClick={onSidebarOpen}
        >
          <MenuIcon />
        </IconButton>
      </Hidden>
    </div>
  </Toolbar>
  );
};

OptionsBar.propTypes = {
  className: PropTypes.string
};

export default OptionsBar;
